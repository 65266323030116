import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { StorageService } from './storage.service';

const USER_SECRET_STORAGE_KEY = 'auth_token';

/** User secret. */
export type UserSecret = Readonly<{

	/** Access token. */
	value: string;

	/** When token should be removed from store. */
	expiry: string;

	/** Current user id. */
	userId: number;
}>;

/** User secret storage. */
@Injectable({ providedIn: 'root' })
export class UserSecretStorageService {

	private readonly storageService = inject(StorageService);

	/** Token info for current user. */
	public readonly currentSecret$ = this.storageService.get<UserSecret | null>(USER_SECRET_STORAGE_KEY);

	/**
	 * Saves a secret.
	 * @param secret Secret to save.
	 */
	public saveSecret(secret: UserSecret): Observable<UserSecret> {
		return this.storageService.save(USER_SECRET_STORAGE_KEY, secret).pipe(map(() => secret));
	}

	/** Removes current secret. */
	public removeSecret(): Observable<void> {
		return this.storageService.remove(USER_SECRET_STORAGE_KEY);
	}
}
