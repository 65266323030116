import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { merge, Observable, of, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { User } from '../models/user';
import { routePaths } from '../utils/route-paths';
import { filterNull } from '../utils/rxjs/filter-null';

import { AppConfigService } from './app-config.service';
import { AuthDto } from './mappers/dto/auth.dto';
import { UserDto } from './mappers/dto/user.dto';
import { UserMapper } from './mappers/user.mapper';
import { UserSecretStorageService } from './user-secret-storage.service';

/**
 * User service.
 * Provides ability to work with current application user.
 */
@Injectable({
	providedIn: 'root',
})
export class CurrentUserService {
	private readonly tokenService = inject(UserSecretStorageService);

	private readonly config = inject(AppConfigService);

	private readonly http = inject(HttpClient);

	private readonly userMapper = inject(UserMapper);

	private readonly router = inject(Router);

	private readonly userValue$ = new ReplaySubject<User | null>(1);

	/**
	 * Current user.
	 * Emits `null` if current user is not authenticated.
	 */
	public readonly currentUser$ = this.initCurrentUser();

	/** Is current user authenticated. */
	public readonly isAuthenticated$ = this.currentUser$.pipe(
		map(user => user !== null),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	/**
	 * Get user by id.
	 * @param userId Current user id.
	 * @returns User.
	 */
	public getCurrentUser(userId: number): Observable<User> {
		const url = new URL(`users/${userId}/`, this.config.apiUrl).toString();

		return this.http.get<UserDto>(url).pipe(
			filterNull(),
			map(response => this.userMapper.fromDto(response)),
		);
	}

	/** Refresh secret. */
	public refreshSecret(): Observable<void> {
		// We don't have refresh token support. That's why we simply logout current user.
		return this.logout();
	}

	/**
	 * Set token and fetch user data.
	 * @param authDto New auth value.
	 */
	public completeAuthorizationProcess(authDto: AuthDto): Observable<User> {
		return this.tokenService
			.saveSecret({
				value: authDto.token,
				expiry: authDto.expiry,
				userId: authDto.user.id,
			})
			.pipe(
				map(() => this.userMapper.fromDto(authDto.user)),
				tap(user => this.userValue$.next(user)),
				filterNull(),
			);
	}

	/** Remove the token and clear the current user. */
	public logout(): Observable<void> {
		return this.tokenService.removeSecret().pipe(
			tap(() => this.userValue$.next(null)),
			switchMap(() => {
				// We perform the same action as in UnauthorizedGuard.
				const { url } = this.router;
				return this.router.navigate(routePaths.login, {
					queryParams: url ? { next: url } : undefined,
				});
			}),
			map(() => undefined),
		);
	}

	private initCurrentUser(): Observable<User | null> {
		const userFromStorage$ = this.tokenService.currentSecret$.pipe(
			switchMap(token => {
				if (!token) {
					return of(null);
				}
				return this.getCurrentUser(token.userId);
			}),
		);

		return merge(userFromStorage$, this.userValue$).pipe(
			shareReplay({ bufferSize: 1, refCount: true }),
		);
	}
}
