import { Injectable } from '@angular/core';

import { ResetPasswordRequestData } from '@asecrmc/common/core/models/reset-password-data';
import { ResetPasswordDto } from '@asecrmc/common/core/services/mappers/dto/reset-password.dto';

import { EntityValidationErrors } from '../../models/app-error';

import { extractErrorMessageByField, ValidationErrorDto } from './dto/validation-error.dto';
import { IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error.mapper';

/** Mapper for reset password entities. */
@Injectable({ providedIn: 'root' })
export class ResetPasswordMapper
implements
		IMapperToDto<ResetPasswordDto, ResetPasswordRequestData>,
		ValidationErrorMapper<ResetPasswordDto, ResetPasswordRequestData> {
	/** @inheritdoc */
	public toDto(data: ResetPasswordRequestData): ResetPasswordDto {
		const dto: ResetPasswordDto = {
			password: data.password,
			password_confirm: data.confirmPassword,
			uid: data.uid,
			token: data.token,
		};
		return dto;
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<ResetPasswordDto>,
	): EntityValidationErrors<ResetPasswordRequestData> {
		return {
			password: extractErrorMessageByField(errorDto, 'password'),
			confirmPassword: extractErrorMessageByField(errorDto, 'password_confirm') ??
				extractErrorMessageByField(errorDto, 'non_field_errors'),
			uid: extractErrorMessageByField(errorDto, 'uid'),
			token: extractErrorMessageByField(errorDto, 'token'),
		};
	}
}
