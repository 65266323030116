import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { Login } from '../../models/login-data';

import { LoginDto } from './dto/auth.dto';
import { extractErrorMessageByField, ValidationErrorDto } from './dto/validation-error.dto';
import { IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error.mapper';

/** Mapper for login entities. */
@Injectable({ providedIn: 'root' })
export class LoginMapper implements
  IMapperToDto<LoginDto, Login>,
  ValidationErrorMapper<LoginDto, Login> {

	/** @inheritdoc */
	public toDto(data: Login): LoginDto {
		const dto: LoginDto = {
			email: data.email,
			password: data.password,
		};
		return dto;
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<LoginDto>): EntityValidationErrors<Login> {
		return {
			email: extractErrorMessageByField(errorDto, 'email'),
			password: extractErrorMessageByField(errorDto, 'password') ?? extractErrorMessageByField(errorDto, 'non_field_errors'),
		};
	}
}
